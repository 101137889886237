section.footer-cta {

	background: $pj-gray-5;

	.footer-container > div {
		@include xy-grid;
		padding: 3.4rem 0;
	}

	h4, .footer-container > div > div {
		@include breakpoint(medium) {
			@include xy-cell(6);
		}
	}
	
	.footer-container > div > div {
		@include breakpoint(small only) {
			display: block;
			width: 100%;
		}
	}
	
	h1, h2, h3,
	h4, h5, h6 {

		color: $pj-gold;

		@include breakpoint(medium) {
			margin-bottom: 0;
		}

	}

	.button {

		margin-bottom: 0;

		@include breakpoint(small only) {
			display: block;
			width: 100%;
		}

		&:hover {
			color: $pj-gray-5;
			background: $black;
		}

	}

}

footer.footer {
	background: $black;
}

.footer-container {
	@include xy-grid-container;
}

.footer-grid {

	@include xy-grid;

	max-width: 90%;
	margin: 0 auto;

	padding: rem-calc(80) 0;

	justify-content: space-between;

	@include breakpoint(small only) {
		padding: rem-calc(50) 0;
	}

	section {

		@include xy-cell();

		@include breakpoint(large) {
			@include xy-cell(auto);
		}

	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.footer-contact {

		order: 2;

		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		@include breakpoint(large) {
			@include xy-cell(3);
		}

	}

	.footer-menu {

		order: 3;

		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		@include breakpoint(large) {
			@include xy-cell(2);
			order: 2;
		}

		li {
			display: block;
			width: 100%;
		}

		.menu-search-link,
		.menu-item-type-cart {

			margin-top: 1rem;

			@include breakpoint(medium) {
				margin-top: 1.2rem;
			}

		}

		a {
			
			width: 100%;
			padding: 0.5rem 0;

			color: $white;
			font-family: "Lulo Bold", sans-serif;
			font-size: 0.7rem;
			line-height: 1;
			text-transform: uppercase;

			background: none;

			@include breakpoint(small only) {
				height: 2rem;
			}

		}

	}

	.footer-logo {

		@include xy-cell(12);

		height: 2rem;
		order: 1;

		@include breakpoint(small only) {
			width: 100%;
			margin: 0 0 1.4rem 0;
		}

		@include breakpoint(medium) {
			@include xy-cell(12);
		}
		
		@include breakpoint(medium only) {
			margin-bottom: 3rem;
		}

		@include breakpoint(large) {
			@include xy-cell(6);
			height: inherit;
			order: 3;
		}

		font-size: 0;
		
		background-image: url('../images/svg/pj-logo.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;

	}

}

.sub-footer {

	color: $white;
	font-family: "Lulo", sans-serif;
	font-size: 0.7rem;
	letter-spacing: 0;
	text-transform: uppercase;

	background: $black;
	border-top: rem-calc(1) solid $white;

	.footer-container {

		@include xy-grid-container;
		@include xy-grid;
		padding: 1.4rem 0;

	}

	.footer-social-icons {
		
		@include xy-cell(12);

		@include breakpoint(small only) {
			margin-bottom: 1.2rem;
			text-align: center;
		}

		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		@include breakpoint(large) {
			@include xy-cell(4);
		}

	}

/*
	.footer-address {

		@include xy-cell(12);

		@include breakpoint(small only) {
			margin-bottom: 1.2rem;
			text-align: center;
		}

		@include breakpoint(medium) {
			@include xy-cell(6);
			text-align: right;
		}

		@include breakpoint(large) {
			@include xy-cell(4);
			text-align: left;
		}

		color: white;

	}
*/

	.footer-copyright {

		@include xy-cell(12);
		line-height: 2;
		text-align: center;

		@include breakpoint(small only) {
			line-height: 1.2;
		}

		@include breakpoint(medium only) {
			margin-top: 1.4rem;
		}
		
		@include breakpoint(large) {
			@include xy-cell(8);
			text-align: right;
		}


	}

}
