/* Shorcodes */


/* FAQ accordion */
.faq-wrapper {
	
	@include xy-grid-container;

	.faq-group {

		@include xy-cell(12);

		margin-bottom: 3rem;

		&:not(:last-of-type) {
		}

	}

	.category-title {
		color: $black;
	}

	.accordion {
		
		background: none!important;
		
		@include breakpoint(medium) {
			padding-left: 3rem;
		}
		
		.accordion-title {
			
			margin: 0.8rem 0 0 0;
			padding: 1.2rem 4rem .4rem 0;
		
			position: relative;
		
			font-family: "Lulo", sans-serif;
			font-size: 0.8rem;
			line-height: 1.6;
			text-transform: uppercase;
			
			background: $white!important;
			border: none;
			border-bottom: rem-calc(1) solid $pj-gold;
			
			&::before {

				display: block;
				width: 1rem;
				height: 1.7rem;

				position: absolute;

				top: 50%;
				right: 0.6rem;

				font-family: "Lulo", sans-serif;
				font-size: 2.2rem;
				line-height: 1;

			}
			
			&:hover,
			&:focus {
				background: none;
			}
			
		}
		
		.accordion-content {

			padding-left: 0;
			padding-right: 0;
			border: none;

			@include breakpoint(medium) {
				padding-top: 2.4rem;
				padding-left: 1.2rem;
			}

			@include breakpoint(large) {
				padding-left: 3rem;
			}


		}
		
	}
	
}


// Phone Numbers
.site-contact-phone,
.site-contact-address {

	dt, dd {

		margin: 0;

		color: $white;
		font-family: "Lulo", sans-serif;
		font-size: 0.7rem;
		letter-spacing: 0;
		text-transform: uppercase;

	}

	dt {
		color: $black;
	}

	dd {

		margin-bottom: 3.1em;

		@include breakpoint(small only) {
			margin-bottom: 1rem;
		}

		a {

			display: inline-block;
			padding-bottom: 0.05rem;

			color: $black;
			font-family: "Lulo", sans-serif;

			border-bottom: 1px solid $pj-gold;

		}

		&:last-of-type {
			@include breakpoint(medium) {
				margin-bottom: 0;
			}
		}

	}

}



.footer-social-icons {
	
	margin-bottom: 0;
	padding: 0;

	line-height: 0;
	list-style: none;

	li {

		display: inline-block;
		width: rem-calc(14);
		height: rem-calc(14);
		margin-right: 1.4rem;

		font-size: 0;

		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;

		&:last-of-type {
			margin-right: 0;
		}

		&.icon-facebook {
			background-image: url('../images/svg/icon-facebook.svg');
		}

		&.icon-twitter {
			background-image: url('../images/svg/icon-twitter.svg');
		}

		&.icon-instagram {
			background-image: url('../images/svg/icon-instagram.svg');
		}

		&.icon-linkedin {
			background-image: url('../images/svg/icon-linkedin.svg');
		}

	}

	a {
		display: block;
		width: 100%;
		height: 100%;
		font-size: 0;
	}
	
}

.site-contact-address address {
	font-style: normal;
	line-height: 1.6;
}

.sidebar .site-contact-address address {
	color: $black;
}
