body.home {
	
	background: $black;
	
	h1.entry-title {
		display: none;
	}
	
	.site-header {

		@include breakpoint(medium) {

			width: 100%;
			position: absolute;

			background: none;
			border-bottom: none;

		}

	}
	
	.top-bar {
		@include breakpoint(medium) {
			background: none;
		}
	}
	
	.top-bar ul {
		@include breakpoint(medium) {
			background: none;
		}
	}

	#homepage-banner {

		display: flex;
		height: 10rem;

		align-items: center;
		justify-content: center;

		position: relative;

		border-bottom: rem-calc(9) solid $pj-gold;

		transition:	.5s linear background-position-y,
					.5s linear opacity;

		@include breakpoint(medium) {
			height: 20rem;
		}
		
		@include breakpoint(medium only) {
			height: 30rem;
		}

		@include breakpoint(large) {
			max-height: rem-calc(580);
			height: rem-calc(580);
		}
		
		.section-wrapper {
//			overflow: hidden;
		}

		#banner-animation {
//			margin-top: rem-calc(20);
		}

		a {

			display: flex;
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			left: 0;

		}

		h2 {

			@include xy-cell(7);

			@include breakpoint(small only) {
				width: 100%;
			}

			@include breakpoint(large) {
				font-size: 2.5rem;
			}

		}

	}
	
}
