// Navigation breakpoints
.mobile-menu,
.site-title-bar {
	@include hide-for(medium);
}

.desktop-menu,
.site-navigation .top-bar-left {
	@include show-for(medium);
}

// Site title
.site-desktop-title a {
	font-weight: bold;
}

// Mobile menu
.mobile-menu {
	display: none; // prevents repaint caused by JS hiding menu onload
}

.off-canvas {
	> ul.menu {
		height: 100vh;
		padding: 1rem;

		a {
			color: $white;
			font-weight: 600;
			font-size: rem-calc(15);
		}
	}

	.menu .active > a { background-color: #ccc; }

}

.title-bar-title {

		display: block;
		width: 17rem;
		height: rem-calc(26);

		font-size: 0;
		
		background-image: url('../images/svg/pj-logo.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;


	a {
	}
}

.mobile-menu,
.mobile-off-canvas-menu {

	.menu .is-active > a {
		background-color: lighten($black, 5%);
	}
}

.menu-icon {
	position: absolute;
	right: 0.5rem;
	top: 0.8rem;
}

.site-header {

	@include breakpoint(large) {
		height: rem-calc(126);
	}

	position: relative;
	z-index: 100;

	background: $black;

	border-bottom: rem-calc(9) solid $pj-gold;

}

// Tablet and desktop menu

.top-bar {

	padding-left: 1rem;
	padding-right: 1rem;

	@include breakpoint(medium only) {
		@include xy-grid;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	@include breakpoint(large) {
		height: rem-calc(117);
	}
	
	.top-bar-left {

		@include breakpoint(medium only) {
			@include xy-cell(12);
		}

		@include breakpoint(large) {
			width: 40%;
			flex: unset;
		}

	}

	.top-bar-right {
		@include breakpoint(medium only) {
			@include xy-cell(12);
		}
	}

	.top-bar-title {
		@include breakpoint(medium only) {
			max-width: 100%;
			width: 100%;
			margin-right: 0;
		}
	}

	> .menu {

		display: flex;
		max-width: 100%;
		width: 100%;

		> li {

			@include breakpoint(medium only) {
				display: inline-block;
			}

			// Logo
			&:first-of-type {

				margin-right: auto!important;
				
				@include breakpoint(medium) {
					width: 100%;
					margin: 0 auto;
				}

				@include breakpoint(large) {
					width: rem-calc(380);
					margin: 0 auto;
				}

				a {

					display: block;
					max-width: rem-calc(380);
					width: 100%;
					height: rem-calc(18);
					margin-left: 1rem;
			
					font-size: 0;
					
					background-image: url('../images/svg/pj-logo.svg');
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
			
					@include breakpoint(medium only) {
						margin: 0 auto;
					}

					@include breakpoint(large) {
						background-position-x: left;
						background-position-y: rem-calc(14);
					}

					&::after {
						display: none!important;
					}

				}

			}

			// Cart link
			&:last-of-type {
				margin-left: auto!important;
			}

		}

	}
	
	.is-dropdown-submenu {
		
		width: 17rem;
		left: -1.1rem!important;
		background-color: $black!important;
		
		li, a {
			width: auto;
		}
		
		a::after {
			display: none!important;
		}
		
	}

	.menu a {

		padding-top: 0;
		padding-bottom: 0;

		color: #e6e6e6;
		font-family: "Lulo Bold", sans-serif;
		font-size: 0.8rem;
		line-height: 1;
		text-transform: uppercase;

		@include breakpoint(medium only) {
			font-size: 0.6rem;
		}

		@include breakpoint(medium) {
			position: relative;
		}

		@include breakpoint(large only) {
			font-size: 0.67rem;
		}

		&::after {
			
			content: '';
			
			display: block;
			width: 0%;
			height: rem-calc(2);
			
			position: absolute;

			bottom: 0;
			left: 50%;

			background: $pj-gold;
			
			transition:	.2s linear left,
						.2s linear width;
			
			@include breakpoint(small only) {
				display: none;
			}
			
		}

		&:hover:not(.button)::after {
			width: 100%;
			left: 0;
		}

	}

	.menu .active > a::after,
	.menu .current-menu-item > a::after {
		width: 100%;
		left: 0;
	}

	.menu .active > a,
	.menu .current-menu-item > a {
		color: $pj-gold;
	}

	.menu > li:not(.menu-text) {

		@include breakpoint(medium only) {
			margin: 0 1rem;
		}

		@include breakpoint(large) {
			margin: 0 0.5rem;
		}

		@include breakpoint(xlarge) {
			margin: 0 1.2rem;
		}

	}

	.menu > li:not(.menu-text) > a {

		padding: 1rem;
		
		@include breakpoint(medium only) {
			padding: 1rem 0;
		}

		@include breakpoint(large) {
			padding: 1rem 0;
		}

	}

	.dropdown.menu .submenu { border: 0; }
	.dropdown.menu .has-submenu.is-down-arrow a { padding-right: 1rem; }
	.dropdown.menu .has-submenu.is-down-arrow > a::after { border: 0; }
	.dropdown.menu:first-child > li.is-dropdown-submenu-parent > a::after { display: none; }

	.menu-search-link,
	.menu-item-type-cart {
		
		/*
		@include breakpoint(medium) {
			margin-left: 5rem!important;
		}
		
		@include breakpoint(large) {
			margin-left: 10rem!important;
		}

		@include breakpoint(xlarge) {
			margin-left: 6rem!important;
		}

		@include breakpoint(xxlarge) {
			margin-left: 20rem!important;
		}
		*/

	}

}

.site-navigation {
	@include breakpoint(small only) {
		padding: 0; // prevents container visibility on small screens
	}
}

// WP post navigation
.post-navigation {
	@include clearfix;
}

.nav-previous {
	float:left;
}

.nav-next {
	float:right;
}
