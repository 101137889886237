/* Lulo Clean One */
@import url('//hello.myfonts.net/count/3545e1');

@font-face {
	font-family:	'Lulo';
	src:			url('../fonts/lulo/3545E1_0_0.eot');
	src:			url('../fonts/lulo/3545E1_0_0.eot?#iefix') format('embedded-opentype'),
					url('../fonts/lulo/3545E1_0_0.woff2') format('woff2'),
					url('../fonts/lulo/3545E1_0_0.woff') format('woff'),
					url('../fonts/lulo/3545E1_0_0.ttf') format('truetype');
}
  
@font-face {
	font-family:	'Lulo Bold';
	src:			url('../fonts/lulo/3545E1_1_0.eot');
	src:			url('../fonts/lulo/3545E1_1_0.eot?#iefix') format('embedded-opentype'),
					url('../fonts/lulo/3545E1_1_0.woff2') format('woff2'),
					url('../fonts/lulo/3545E1_1_0.woff') format('woff'),
					url('../fonts/lulo/3545E1_1_0.ttf') format('truetype');
	
}


/* Helvetica Neue */
@font-face {
	font-family:	'Helvetica Neue 45';
	src:			url('../fonts/helvetica-neue/HelveticaNeueLTStd-Lt.otf');
}
