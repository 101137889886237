body {
	background: $white;
}

h1, h2, h3,
h4, h5, h6 {
	
	margin-bottom: 1rem;
	
	br {

		display: none;

		@include breakpoint(xlarge) {
			display: inline;
		}

	}
	
}

section p {
	margin-bottom: 1.6rem;
}

body.home .main-grid {
	overflow: initial;
}

.main-grid {

	@include xy-grid();
	
	overflow: hidden;

	&.sidebar-left {
		// Place the sidebar below main content on small screens ...
		@include breakpoint(small) {
			.main-content { order: 1; }
			.sidebar { order: 2; }
		}

		// ... and to the left on medium-up screens, when using the sidebar-left template
		@include breakpoint(medium) {
			.main-content { order: 2; }
			.sidebar { order: 1; }
		}
	}

	// Full width template
	.main-content-full-width {
		@include xy-grid;
		display: block;
		max-width: 100%;
		width: 100%;
	}

	// Default template
	.main-content {

		@include breakpoint(small) {
			@include xy-cell(12);
		}

		@include breakpoint(large) {
			@include xy-cell(9);
			margin-top: 1rem;
		}

	}

	.entry-title {
		display: none;
	}

	.sidebar {

		@include breakpoint(small) {
			@include xy-cell(12);
		}

		@include breakpoint(large) {
			@include xy-cell(3);
			margin-top: 1rem;
		}

	}

}

.page-template-page-sidebar {
	
	@include breakpoint(large) {
	
		.main-wrap {
			max-width: 100rem;
			margin-left: auto;
			margin-right: auto;
		}
		
		.main-content {
			max-width: 70%;
			float: left;
		}
		
		.sidebar {
			max-width: 40%;
			margin-top: 9.2rem;
			float: left;
		}

	}

}


.page-banner {
	
	@include xy-grid-container;
	
//	margin-bottom: 3.4rem;
	
	position: relative;
	
	background: $pj-gray-5;
	
	@include breakpoint(small only) {
		overflow: hidden;
	}
	
	@include breakpoint(medium) {
		&::after {
			
			content: '';
			
			display: block;
			width: 100000%;
			height: 100%;
			
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: -500%;
			
			z-index: -1;

			background: $pj-gray-5;
			
		}
	}
	
	.wp-post-image {

		margin: 3rem 0;

		@include breakpoint(small only) {
			max-width: initial;
			width: calc(100% + 1.6rem);
			margin-top: 0;
			margin-left: -0.8rem;
		}
		
		@include breakpoint(large) {
			margin: 4.2rem 0;
		}

	}
	
	.wp-post-image + .banner-group {
		margin-top: 0;
	}
	
}

.banner-group {
	
	@include xy-grid();
	
	margin: 3rem 0;

	@include breakpoint(large) {
		margin: 4.2rem 0;
	}
	
	> * {
		margin-bottom: 0;
	}

	.banner-heading {
		color: $pj-gold;
	}

	// Heading only
	&.banner-1col-heading {
		
		max-width: 50rem;
		margin-left: auto;
		margin-right: auto;
		
		.banner-heading {
			@include xy-cell(12);
			text-align: center;
		}
		
	}

	// Heading and text (centered)
	&.banner-1col-heading-text {

		max-width: 50rem;
		margin-left: auto;
		margin-right: auto;
		
		.banner-heading,
		.banner-content {
			@include xy-cell(12);
			text-align: center;
		}
		
		.banner-heading {
			margin-bottom: 2.2rem;
		}
		
	}

	// 2 columns (heading + text)
	&.banner-2cols {
		
		.banner-heading,
		.banner-content {
			@include breakpoint(medium) {
				@include xy-cell(6);
			}
		}
		
	}


/*	
	.banner-heading {
		@include xy-cell(6);
		color: $pj-gold;
	}
	
	.banner-content {
		@include xy-cell(6);
	}
*/

}

article {
	
	> section {

		display: flex;

		padding-top: 6rem;
		padding-bottom: 6rem;

		align-items: center;
		justify-content: center;

		@include breakpoint(small only) {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

	}

	.alternating-wrapper {

		padding-top: 6rem;
		padding-bottom: 6rem;

		@include breakpoint(small only) {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

	}
	
	.section-wrapper {
		@include xy-grid-container;
	}
	
	ul.list-2-columns,
	ul.list-3-columns {

		list-style: none;

		li {
			text-align: left;
		}
		
	}
	
	ul.list-2-columns {

		@include breakpoint(medium) {
			column-count: 2;
			column-gap: 10px;
		}
		
	}
	
	ul.list-3-columns {

		@include breakpoint(medium) {
			column-count: 3;
			column-gap: 10px;
		}
		
	}
	
}

/* Section Coloring */
.section-light {

	background: $white;
	
	h1, h2, h3,
	h4, h5, h6,
	p, span, a {
//		color: $white;
	}
	
	h1, h2, h3,
	h4, h5, h6, {
		color: $pj-gold;
	}
	
}

.section-dark {
	
	background: $black;
	
	h1, h2, h3,
	h4, h5, h6,
	p, span, a,
	dt, dd {
		color: $white!important;
	}
	
	.button-hollow {
		
		&:hover {
			color: $black!important;
		}
		
	}
	
}


/* Page Builder */
.has-background {

	background-repeat: no-repeat;
	
	&.background-left {
		background-position: left center;
		background-size: contain;
	}
	
	&.background-center {
		background-position: center center;
	}
	
	&.background-right {
		background-position: right center;
		background-size: contain;
	}
	
}

.image-section {
	
	min-height: 15rem;

	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	
	@include breakpoint(medium) {
		min-height: 28rem;
	}
	
}

.is-parallax {
	background-attachment: fixed;
}

.section-content {
	@include breakpoint(small only) {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

.section-full {
	
	.section-wrapper {
		max-width: 100%;
		width: 100%;
	}
	
}

.section-1-column {

	display: block;

	text-align: center;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	.section-wrapper {
		max-width: 42rem;
	}
	
}

.section-2-columns {
	
	@include breakpoint(medium) {

		.section-wrapper {
			@include xy-grid;
		}
		
		.section-wrapper > * {
			@include xy-cell(6);
		}
		
	}
	
}

.alternating-wrapper {
	width: 100%;
	margin: 0;
}

.content-left,
.content-right {

	.section-wrapper {

		@include xy-grid;

		display: flex;

		align-items: center;
		justify-content: center;

		> div:not(.section-content) {
			@include breakpoint(small only) {
				max-width: 20rem;
			}
		}

	}

	@include breakpoint(medium) {

		.section-wrapper > div:first-of-type {
			@include xy-cell(5);
		}
	
		.section-wrapper > div:last-of-type {
			@include xy-cell(7);
		}

	}
	
}

.content-left {
	
	@include breakpoint(medium) {
		
		.section-wrapper > div:first-of-type {
			order: 1;
		}
	
		.section-wrapper > div:last-of-type {
			order: 2;
		}

	}

	@include breakpoint(large) {
		.section-content {
			padding-right: 5rem;
		}
	}

}

.content-right {
	
	@include breakpoint(medium) {
		
		.section-wrapper > div:first-of-type {
			order: 2;
		}
	
		.section-wrapper > div:last-of-type {
			order: 1;
		}

	}

	@include breakpoint(large) {
		.section-content {
			padding-left: 5rem;
		}
	}

}

.content-left,
.content-right {
	
	@include breakpoint(small only) {

		.section-wrapper > div:first-of-type {
			order: 2;
		}
	
		.section-wrapper > div:last-of-type {
			order: 1;
		}

	}

}

/* Tabbed Content */
.content-tab-section {
	
	@include breakpoint(medium) {
		padding-top: 2rem;
		padding-bottom: 0;
	}
	
	@include breakpoint(large) {
		padding-top: 4rem;
	}
	
	.tabs,
	.tabs-content,
	.accordion,
	.accordion-content {

		background: none;
		border: none!important;

		a {

//			color: $pj-gold!important;
			font-size: 0.9rem;
			font-family: "Lulo Bold", sans-serif;
			
			border: none;
			
			&:hover {
//				color: $white!important;
			}

		}

	}
	
	.tabs,
	.accordion {

		@include breakpoint(large) {
			text-align: center;
		}

		.tabs-title,
		.accordion-title {

			font-family: "Lulo Bold", sans-serif;

			border: none;
			border-bottom: rem-calc(1) solid $pj-gold;

			@include breakpoint(large) {
				display: inline-block;
				margin: 0 1rem;
				float: none;
			}
			
			@include breakpoint(large) {
				margin: 0 3rem;
			}

			&:hover,
			&:hover a,
			&:focus,
			&.is-active,
			&.is-active a {
				color: $pj-gold!important;
				background: none;
			}

			a {

				@include breakpoint(large) {
					padding: 0 0 .3rem 0;
					font-size: 1rem;
				}
				
			}
			
		}
		
	}
	
	.accordion-content,
	.tabs-content {
		
		a {
			
			color: $pj-gold!important;
			
			&:hover {
				color: $white!important;
			}
			
		}
		
	}
	
	.accordion-content {

		img {
			display: none;
		}

	}
	
	.tabs-content {
		
		@include breakpoint(medium) {
			padding-top: 2rem;
		}
		
		.tabs-panel {

			padding-bottom: 0;

			img {
				display: inline-block;
				width: 32%;
			}
		
			h3 {
				@include breakpoint(small only) {
					font-size: 0.8rem;
				}
			}
			
			> div {

				display: inline-block;
				width: 62%;
				margin-left: 5%;
				padding-top: 2rem;


			}

		}
		
	}
	
}


// Contact Us page
.page-id-17 {
	
	h3 {
		@include breakpoint(medium) {
			padding-left: 2rem;
		}
	}
	
	article {
		
		@include breakpoint(medium) {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
		
		@include breakpoint(medium) {
			margin-top: 4rem;
			margin-bottom: 4rem;
		}
		
	}
	
	.sidebar {
		
		@include breakpoint(large) {
			margin-top: 9.2rem;
		}
		
	}
	
}
