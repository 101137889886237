
.woocommerce-breadcrumb {
	display: none;
}

.woocommerce-error {
	
	margin: 0;
	padding: 2rem 1rem;

	position: relative;
	top: -3rem;

	color: $white;
	list-style: none;
	
	&::after {
		
		content: '';
		
		display: block;
		width: 1000%;
		height: 100%;
		
		position: absolute;
		top: 0;
		right: 0;
		left: -500%;

		z-index: -1;

		background: $pj-red;
		
	}

	li {
		
		margin: 0;
	
		font-family: "Lulo", sans-serif;
		font-size: 0.8rem;
		text-align: center;

		@include breakpoint(medium) {
			font-size: 1rem;
		}
		
		@include breakpoint(large) {
//			font-size: 1.2rem;
		}
		
		a {
			color: $white!important;
			border-bottom: rem-calc(1) solid $white!important;
		}
	
	}

}

.main-container .woocommerce {

	@include xy-grid-container;

	h1, h2, h3,
	h4, h5, h6 {
		@include xy-cell(12);
	}
	
	.woocommerce-form {
		max-width: 100%!important;
		width: 100%!important;
	}

	.woocommerce-form-login {
/*		
		// username or email
		> p:nth-of-type(1) {
			@include breakpoint(medium) {
				@include xy-cell(6);
			}
		}
		
		// password
		> p:nth-of-type(2) {
			@include breakpoint(medium) {
				@include xy-cell(6);
			}
		}
*/		
	}


	.wpmc-step-login {
		
		@include xy-grid;
		
		.woo-returning-customers,
		.woo-guest-checkout {

			@include xy-cell(12);
			padding-top: 2rem;
			padding-bottom: 2rem;

			@include breakpoint(medium) {
				@include xy-cell(6);
			}

			h1, h2, h3,
			h4, h5, h6 {
				margin-left: 0;
				margin-right: 0;
			}

			p.form-row,
			p.lost-password {
				width: 100%;
				margin: 0 0 1.9rem 0;
			}

		}
		
		.woo-returning-customers {

			padding: 1.8rem;
			border: rem-calc(3) solid $pj-gold;

			.button {

				@include breakpoint(small only) {
					width: 100%;
				}

				@include breakpoint(medium only) {
					width: 100%;
				}

				@include breakpoint(large) {
					float: right;
				}

			}

			label.inline {

				@include breakpoint(large) {
					margin: 1.1rem 1rem 0 0;
					float: right;
				}


			}
			
			.lost_password {

				width: 100%;
				margin: 0;

				@include breakpoint(large) {
					text-align: right;
				}

			}

		}
		
	}



	
	.coupon {
		display: none;
	}
	
	.shop_table {
		
		@include breakpoint(small only) {
			width: calc(100% + 2.2rem);
			margin-left: -1.2rem;
			padding-left: 0!important;
		}
		
		td.product-quantity {
			width: 5rem!important;
		}
		
		.tax-total {
			small {
				display: none;
			}
		}
		
		.product-thumbnail {
			@include breakpoint(small only) {
				display: none;
			}
		}
		
		.quantity input {
			margin-bottom: 0;
		}
		
	}
	
	.actions,
	.wc-proceed-to-checkout {
		@include breakpoint(small only) {
			button, .button {
				width: 100%;
			}
		}
	}
	
}
				



/* Product Drilldown widget for homepage */
.products-drilldown {

	@include xy-grid-container;

	display: block;
	width: 100%;

	.section-wrapper { @include xy-grid; }

	h2 {
		color: $black;
	}
	
	.product-list {

		@include breakpoint(small only) {
			order: 2;
			margin-top: 3rem;
		}
		
		@include breakpoint(medium) {
			@include xy-cell(5);
		}
		
		// menu wrapper
		.menu.accordion-menu {
			
			> li:first-of-type > ul {
				display: flex;
			}
			
			a:not(.buy-product) {

				display: inline-block;
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 0.2rem;

				border-bottom: rem-calc(1) solid $pj-gold;
	
				&::after {
					display: none;
				}

			}
			
		}
		
//		.button-group {
			
			a {

/*
				display: inline-block;
				width: auto;
				padding: 1.2rem;

				color: $pj-gold;
				font-family: "Lulo Bold", sans-serif;
				font-size: 0.9rem;

				border: none!important;
*/
				&.buy-product {

					display: inline-block;
					width: auto;
					margin-top: .4rem;
					padding: 1.2rem;
	
					color: $pj-gold;
					font-family: "Lulo Bold", sans-serif;
					font-size: 0.9rem;
	
					border: none!important;


					
					margin-right: 1.2rem;
					
					color: $white;
					
					background-color: $pj-gold;
					
				}
				
				&.learn-more-product {
//					@extend .button-hollow;
				}

			}
			
//		}

		.product-details {
			display: block;
			margin-left: 0;
		}
		
		a.buy-product {
/*
			display: inline;
			padding: 0;
			
			color: $pj-gold;
			font-family: "Lulo Bold", sans-serif;
			font-size: 0.9rem;

			background: none;
			
			border: none;
*/			

			line-height: 1.6;

		}
		
		a.learn-more-product {

			margin-top: .4rem;
			padding: 1.4rem 1.4rem!important;
		
			color: $white;
		
			font-family: "Lulo", sans-serif;
			font-size: rem-calc(13);
			font-weight: bold;
			text-transform: uppercase;
		
			background: $pj-gray-4;
		
			border: rem-calc(1) solid $pj-gray-4!important;
			
			transition:	.3s linear color,
						.3s linear background,
						.3s linear border;
			
			&:hover,
			&:active,
			&:focus {
				color: $pj-gray-4;
				background: none;
			}

		}
		
		.category > ul {

			margin-left: .6rem;
			padding: 0.8rem 0;

			@include breakpoint(medium) {
				margin-left: 1.2rem;
			}

			@include breakpoint(large) {
				margin-left: 3rem;
			}

		}
		
		.category-title {
			color: $black;
			font-family: "Lulo Bold", sans-serif;
			line-height: 1.2;
			text-transform: uppercase;
		}
		
		.product {

			margin: .4rem 0;

			&.is-accordion-submenu-parent[aria-expanded="true"] > a {
				color: $pj-gold;
				font-family: "Lulo Bold", sans-serif;
				pointer-events: none;
			}

		}
		
		.product-title {
			color: $black;
			font-family: "Lulo", sans-serif;
			font-size: 0.9rem;
			line-height: 1.2;
			text-transform: uppercase;
		}
		
		.product-details {

			margin: 1.2rem 0 1rem 2.5rem;

			@include breakpoint(small) {
				margin-left: 0rem;
			}

			@include breakpoint(medium) {
				margin-left: 0rem;
			}

			@include breakpoint(large) {
//				margin-left: 2.5rem;
			}

		}
		
	}
	
	.product-images {

		@include breakpoint(small only) {
			@include xy-cell(12);
			order: 1;
		}
		
		@include breakpoint(medium) {
			
			@include xy-cell(7);
			
			display: flex;
			align-items: center;
			
		}
		
		.orbit-slide {
			text-align: center;
		}
		
	}
	
}


/* Product Category filter */
.shop-category-nav {
	
	max-width: 100%;
	width: 100%;
	margin-bottom: 2rem;
	padding: 1.4rem 0;
	
	position: relative;
	
	overflow: visible;
	
	@include breakpoint(large) {
		@include xy-grid-container;
		margin-bottom: 5rem;
	}
	
	&::after {

		content: "";

		display: block;
		width: 5000%;

		position: absolute;
		top: 0;
		left: -1250%;
		bottom: 0;
		
		z-index: -1;

		background: $black;

	}
	
	.category-nav-wrapper {

		@include xy-grid;

		@include breakpoint(large) {

			padding-top: 2rem;
			padding-bottom: 2rem;

			* { margin-bottom: 0!important; }

		}

		@include breakpoint(xlarge) {
			padding-top: 4rem;
			padding-bottom: 4rem;
		}

	}
	
	h2 {

		width: 100%;
		padding-bottom: .6rem;
		text-align: center;

		@include breakpoint(large) {

			@include xy-cell(3);

			padding-bottom: 0;
			text-align: left;

		}

	}
	
	.shop-categories {

		width: 100%;
		margin: 0;
		padding: 0;

		list-style: none;

		@include breakpoint(large) {
			@include xy-cell(9);
			@include xy-grid;
		}

		li {

			font-family: "Lulo Bold", sans-serif;

			text-align: center;
			
			@include breakpoint(large) {
	
				@include xy-cell(auto);

				display: flex;
				align-items: center;
				justify-content: center;
				
				text-align: center;

			}

			a {

				transition: .3s linear color;

				@include breakpoint(large) {
					font-size: 0.7rem;
					border-bottom: rem-calc(1) solid $pj-gold;
				}

				@include breakpoint(xlarge) {
					font-size: 0.9rem;
				}

			}

		}

		li:not(:last-of-type) {
			margin-bottom: .5rem;
		}

	}
	
}

body.post-type-archive-product .shop-category-all a,
body.product-category-15 .shop-category-15 a,
body.product-category-17 .shop-category-17 a,
.category-nav-wrapper li:hover a {
	color: $pj-gold!important;
}


/* Main Products/Categories templates */
body.post-type-archive-product,
body.product-category {
	
	.main-content-full-width {

		@include xy-grid-container;

		max-width: 100%;
		width: 100%;
		
		overflow: hidden;

		> article {
			@include xy-grid;
		}

		h1.page-title {
			display: none;
		}

		.woocommerce-result-count { display: none; }
				
		.woocommerce-ordering {
			
			@include xy-cell(12);
			
			@include breakpoint(medium) {

				margin-bottom: 2rem;

				position: relative;
				overflow: auto;
			
				&::before {
					
					content: "Sort By";
					
					display: block;
					
					position: absolute;
					top: 0.6rem;
					right: calc(25% + 1rem);

					color: $pj-gold;							
					font-family: "Lulo Bold", sans-serif;
					font-size: .9rem;
					text-transform: uppercase;
					
				}

				select.orderby {
					width: 25%;
					float: right;
				}

			}					
			
		}
		
		.products {

			@include xy-grid;

			margin-top: 4.2rem;
			margin-left: 0;
			list-style: none;

			.product {
				
				margin-bottom: 5rem;
				text-align: center;
				
				@include breakpoint(small) {
					@include xy-cell(12);
				}
				
				@include breakpoint(medium) {
					@include xy-cell(6);
				}
				
				@include breakpoint(large) {
					@include xy-cell(4);
				}
				
				h2 {
					
					max-width: 18rem;
					margin: 2.4rem auto 0.4rem auto;
					
					color: $black;
					font-size: .9rem;
					
					a { color: $black; }
					
				}
				
				.price {
					color: $black;
					font-family: "Lulo", sans-serif;
					font-size: 1.1rem;
					letter-spacing: -0.2rem;
				}
				
				.button {
					display: none;
				}
				
			}

		}
				
	}
	
	.footer-cta {
		display: none;
	}
	
}


/* Pagination */
.woocommerce-pagination {

	margin-bottom: 3rem;
	text-align: center;
	
	@include breakpoint(medium) {
		margin-bottom: 5rem;
		text-align: right;
	}
	
	.page-numbers {

//		@include pagination-container;

		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			margin: 0 .3rem;
			padding: 0;
		}

		a, span {
		
			margin: 0;
			padding: 0 0.1rem .1rem .2rem;
		
			color: $black;
			font-family: "Lulo", sans-serif;
			font-size: 1.2rem;
			text-align: center;
		
			border-bottom: rem-calc(1) solid rgba($pj-gold, 0);
		
			transition: .3s linear border-bottom;
		
		}

		&.current,
		a:hover {
			
			border-bottom: rem-calc(1) solid rgba($pj-gold, 1);
			
			&::before, &::after {
				display: none;
			}
			
		}

	}
	
}


/*! Checkout Process */

article > .woocommerce {
	
	@include xy-grid-container;

	padding: 2rem 1rem;
	
	@include breakpoint(medium) {
		padding: 4rem 2rem;
	}
	
}

.woocommerce-cart {
	
	.woocommerce-cart-form {
		padding-left: 0;
		padding-right: 0;
	}
	
	.shop_table {
		button, .button,
		input {
			margin-bottom: 0;
		}
	}
	
	.button[disabled] {
		background-color: $pj-gold;
	}
	
	.product-remove {
		
		a {
			
			display: block;
			width: 100%;
			height: 100%;
			
			color: $pj-red;
			font-size: 1.6rem;
			font-weight: 600;
			text-align: center;
			
			border: none;
			
		}
		
	}
	
	.product-thumbnail {
		
		max-width: 10rem;
		
		@include breakpoint(small only) {
			display: none;
		}
				
	}
	
	.product-name {
		
	}
	
	.product-price {
		
	}

	.product-quantity {
	
		input {
			max-width: 4rem!important;
		}
		
	}
	
	.product-subtotal {
		
	}

}

.woocommerce-message {
	
	.button {
		margin: 0 1.2rem 0 0;
	}
	
}

.woocommerce-NoticeGroup {
	display: none;
}

.main-container .woocommerce {
	
	padding-top: 3rem;
	
	
	
	
	
	.wpmc-tabs-wrapper {
		
		margin-bottom: 2rem;

		@include breakpoint(medium) {
			width: calc(100% + 2.4rem);
			margin-left: -1.2rem;
		}

		.wpmc-tab-number {
			line-height: 1.9;
			text-indent: 0.15rem;
		}
		
		.wpmc-tab-number,
		.wpmc-tab-text {
			font-family: "Lulo", sans-serif;
		}
		
		.current {
			
			&::before {
				border-bottom-color: $pj-gold;
			}
		
			.wpmc-tab-number {
				font-family: "Lulo Bold", sans-serif;
				border-color: $pj-gold;
			}
			
			.wpmc-tab-text {
				font-family: "Lulo Bold", sans-serif;
			}
			
		}
		
	}

	.wpmc-steps-wrapper {

		@include xy-grid;
		margin-top: 3rem;

		.wp-multi-step-checkout-step {
			
			p:first-of-type {
				@include xy-cell(12);
				text-align: center;
			}
			
		}
		
		.wpmc-step-review.current {
			
			@include xy-grid;

			.shop_table {
				
				@include xy-cell(12);
				
				@include breakpoint(medium) {
					@include xy-cell(5);
					order: 2;
				}


			}
			
			#payment {
				
				@include xy-cell(12);
				
				@include breakpoint(medium) {
					@include xy-cell(7);
					order: 1;
				}

				ul .form-row {
					max-width: 100%;
					width: 100%;
					margin-left: 0!important;
					margin-right: 0!important;
				}
				
				.wc_payment_method {
					
					> label {
						
						margin: 0 0 1.9rem 0; 
						font-size: 0;

						img {
							margin-right: .4rem;
						}

					}
					
				}
				
				.payment_box {
					
					> p {
						display: none;
					}
					
				}

			}
			
		}

	}

	.wpmc-nav-wrapper {
		@include xy-grid-container;
		margin-top: 3rem;
	}
	
	.woocommerce-form {

		@include xy-grid;

		max-width: 100%;
		width: 100%;
		padding-left: 0;
		padding-right: 0;

		> p:first-of-type {
			margin-bottom: 2.4rem;
			font-weight: 600;
		}

		.clear {
			display: none;
		}
		
		input {
			margin-bottom: 0;
		}

/*
		&.woocommerce-form-login {
			
			.form-row-first,
			.form-row-last {
				
				@include xy-cell(12);
				
				@include breakpoint(medium) {
					@include xy-cell(5);
				}
				
			}
			
			p:nth-of-type(4) {

				@include breakpoint(small) {
					@include xy-cell(12);
				}

				@include breakpoint(medium) {
					@include xy-cell(2);
				}

				.button {

					width: 100%;

					@include breakpoint(medium) {
						margin: 1.5rem 0 0 0;
						padding: 0.9rem 0;
					}

				}

				.woocommerce-form__label-for-checkbox {
					display: none;
				}

			}
			
			.lost_password {
				@include xy-cell(12);
			}
			
		}
*/


	}

	.wpmc-nav-wrapper {
		
		@include xy-grid();
		@include xy-grid-collapse();
		
		@include breakpoint(small only) {
			padding-left: 0;
			padding-right: 0;
		}
		
		@include breakpoint(medium) {
			padding-right: 0;
		}
		
		.wpmc-footer-left,
		.wpmc-footer-right {

			display: block!important;
			float: initial;

			@include breakpoint(small) {
				@include xy-cell(6);
			}

			.button {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}

		}

		.wpmc-footer-left {
			
			@include breakpoint(small only) {
				display: none;
			}			
			
		}
		
		.wpmc-footer-right {
			
			@include xy-grid;
			text-align: right;
			
			@include breakpoint(small only) {
				max-width: 100%;
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}			

			@include breakpoint(medium) {
				@include xy-cell(5);
				@include xy-cell-offset(7);
				margin-right: 0;
			}
			
			.button {
//				@extend .button-hollow;
//				@include xy-cell(6);
				display: inline-block;
				width: 50%;
			}

			#wpmc-prev,
			#wpmc-next {
				display: inline-block;
			}

			#wpmc-prev {

				color: $black;

				background: none;
				border: none;

				&:hover {
					color: $pj-gold;
				}

			}

			#wpmc-next {
//				margin-right: 0;
			}

			#wpmc-skip-login {
				width: 100%;
			}
			
		}
		
	}
	
}


/*! Single Products */


.product.type-product {
	@include breakpoint(medium) {
//		@include xy-grid-collapse('div', margin, left right, medium);
	}
}

.single-product {
	
	.product.type-product {
		@include xy-grid;
	}
	
	.woocommerce-product-gallery__trigger {
		display: none;
	}
	
	.woocommerce-product-gallery {

		padding-top: 3rem;
		padding-bottom: 3rem;

		@include breakpoint(small only) {
			width: 100%;
		}

		@include breakpoint(medium) {
			@include xy-cell(45%);
		}

		.wp-post-image {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}

		.woocommerce-product-gallery__image {
			
			> a, > a:hover {
				pointer-events: none;
			}
			
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
			
		}

		.flex-control-nav {

			display: block;
			width: 100%;
			margin: 0 auto;
			
			list-style: none;
			text-align: center;

			li {

				display: inline-block;
				margin: .8rem .8rem 0 .8rem;

				&:hover {
					cursor: pointer;
				}

			}
			
		}

	}
	
	.product_title {

		font-size: 1.2rem;

		@include breakpoint(small only) {
			text-align: center;
		}

	}
	
	.price {

		font-family: "Lulo", sans-serif;
		font-size: 1.1rem;

		@include breakpoint(small only) {
			text-align: center;
		}

	}
	
	.entry-summary {

		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		padding-top: 3rem;
		padding-bottom: 3rem;

//		background: $pj-gray-5;
		
		@include breakpoint(small only) {
			width: 100%!important;
			padding-left: 1rem;
			padding-right: 1rem;
		}
		
		@include breakpoint(medium) {
			@include xy-cell(55%);
			margin-right: 0!important;
		}
		
		@include breakpoint(medium only) {
			width: 52.6%!important;
			padding-left: 4rem;
			padding-right: 4rem;
		}
		
		@include breakpoint(large) {
			width: 53.5%!important;
			padding-left: 6rem;
			padding-right: 6rem;
		}
		
		@include breakpoint(large only) {
			@include xy-cell(55%);
		}
		
		> * {
			display: block;
			width: 100%;
		}
		
		.entry-title {
			display: block
		}

		.cart {
			padding: 0;
		}
		
		.sku_wrapper {
			display: none;
		}
		
		.posted_in {
			display: none;
		}
		
		.button {
			@include breakpoint(small only) {
				width: 100%;
			}
		}
		
	}
	
	.quantity {
		
		label {

			display: block;
			width: auto;
			height: auto;
			
			position: relative!important;
			
			color: $pj-gold;
			font-family: "Lulo Bold", sans-serif;
			
			clip: initial;
    
		}
		
		input {

			max-width: 4rem;
			background: $white;
			border: rem-calc(1) solid $pj-gray-4;

			@include breakpoint(medium) {
				width: auto;
			}

		}
		
	}
	
	.product-blurb {

		max-width: 100%;
		width: 100%;
		
		padding-top: 4rem;
		padding-bottom: 4rem;

		.section-wrapper {
			@include xy-grid-container;
		}

	}

	.featured-ingredients-header {

		display: block;
		max-width: 100%;
		width: 100%;
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;

		color: $pj-gold;
		text-align: center;

	}

	#featured-ingredients {

		@include xy-grid-container;

		padding-top: 2rem;
		padding-bottom: 2rem;

		border-top: rem-calc(1) solid $pj-gray-4;
		border-bottom: rem-calc(1) solid $pj-gray-4;

	}

	.product-ingredients {
		
		max-width: 100%;
		width: 100%;

		.section-wrapper {
			@include xy-grid-container;
		}
		
	}

	#product-featured-review {

		max-width: 100%;
		width: 100%;
		
		padding-top: 4rem;
		padding-bottom: 4rem;
		
		background: $pj-gray-5;

		.section-wrapper {
			@include xy-grid-container;
		}

	}

	.related.products {

		@include xy-grid-container;

		max-width: 100rem;
		width: 100%;

		clear: both;

		@include breakpoint(medium) {
			padding-bottom: 2rem;
		}

		@include breakpoint(large) {
			padding-bottom: 3rem;
		}

		> h2 {

			margin: 1.8rem 0;

			color: $pj-gold;
			text-align: center;

			@include breakpoint(medium) {
				margin: 4rem 0 3rem 0;
			}

		}
		
		.products {

			@include xy-grid;
			margin-left: 0;
			list-style: none;
			
			.product {

				@include xy-cell(12);
				
				padding-bottom: 1.6rem;
				
				position: relative;

				@include breakpoint(small only) {
					margin-bottom: 2.4rem;
					text-align: center;
				}

				@include breakpoint(medium) {
					@include xy-cell(4);
				}

				&:last-of-type {
					margin-bottom: 0;
				}
				
				a {
					border: none;
				}

				> a:first-of-type {
					display: block;
					width: 100%;
					height: 100%;
					text-align: center;
				}
				
				h2 {
					margin: 1.4rem 0 0.8rem 0;
					font-family: "Lulo", sans-serif;
					font-size: 0.8rem;
				}

				.price {
					display: none;
				}

				.button {
					display: none;
				}
				
				.woocommerce-loop-product__link {

					&::after {
						
						content: 'Learn More >';
						
						display: block;
						width: 100%;
						height: auto;
						
						position: absolute;
						bottom: 1rem;
						left: 0;

						color: $pj-gold;
						font-family: "Lulo Bold", sans-serif;
						font-size: 0.7rem;
						letter-spacing: rem-calc(1);
						
					}

				}

			}
			
		}
		
		.product {
			
			a {
				display: block;
				width: 100%;
			}
			
		}

	}
	
}

// temporary
.woocommerce-ordering {
	display: none;
}

.product-refill-callout {
	font-weight: bold;
}



.shop_table {

	tbody {

		tr.cart_item {
			background-color: $white;
			border-bottom: rem-calc(1) solid $pj-gray-5;
		}

	}
	
}
