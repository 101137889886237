.featured-hero {

	width: 100%;
	height: rem-calc(200);

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@include breakpoint(medium) {
		height: rem-calc(480);
	}

	@include breakpoint(xlarge) {
		height: rem-calc(720);
	}

}
