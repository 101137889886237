.button {
	
	padding: 1.4rem 3rem;

	font-family: "Lulo", sans-serif;
	font-size: 0.6rem;
	text-transform: uppercase;

	background: $pj-gold;

	border: rem-calc(1) solid $pj-gold;
	
	transition:	.3s linear color,
				.3s linear background,
				.3s linear border;
	
	&:hover,
	&:active,
	&:focus {
		color: $pj-gold;
		background: none;
	}

}

.button-hollow {

	color: $pj-gray-3;

	background: none;
	border: rem-calc(1) solid $pj-gray-3;
	
	&:hover,
	&:active,
	&:focus {
		color: $pj-gray-3;
		background: $white;
		border: rem-calc(1) solid $white;
	}

}
