/*
Geo My WP styles
*/



/* Store Locator page */
.page-id-243,
.page-id-721 {
	
	.locator-intro {

		display: block;
		width: 100%;
		padding: 0 1.2rem 2rem 1.2rem;
		text-align: center;

		h2 {
			text-align: center;
		}
	
	}
	
	.main-content-full-width {
		
		section {
			@include xy-grid;
			align-items: flex-start;
		}
		
		#locator-search {
			
			@include xy-cell(12);
			
			@include breakpoint(medium) {
				@include xy-cell(5);
			}
			
			@include breakpoint(large) {
				@include xy-cell(4);
			}
			
			@include breakpoint(xxlarge) {
				@include xy-cell(3);
			}
			
			> div {
				max-width: 100%;
				width: 100%;
			}
			
		}
		
		#locator-results {
			
			@include xy-cell(12);
			
			@include breakpoint(medium) {
				@include xy-cell(7);
			}
			
			@include breakpoint(large) {
				@include xy-cell(8);
			}

			@include breakpoint(xxlarge) {
				@include xy-cell(9);
			}
			
		}
		
	}

}




// Search form
.gmw-form-wrapper {
	
	@include breakpoint(medium) {
		margin-top: 1rem;
	}
	
	form {
		margin: 0;
	}
	
	.gmw-field-label {
		display: none;
	}
	
	input[type="submit"] {
		@extend .button;
	}

}

// Results
.gmw-results-wrapper {

	.gmw-pt-pagination-wrapper {
		display: none;
	}

	.gmw-results-count {
		display: none;
	}

	li.single-post {

		margin-bottom: 1.2rem;
		padding: 0.8rem;

		list-style: none!important;

		h2 {
			font-size: 0.9rem;
		}
		
		.distance {
			display: block;
			margin-top: -0.8rem;
			margin-bottom: 1.2rem;
		}
		
		.gmw-directions-link {
			align-self: flex-end;
		}

		.gmw-get-directions {
			@extend .button;
			margin: .8rem 0 0 0!important;
		}
		
		a:not(.gmw-get-directions) {
			color: $pj-gold;
			font-weight: 600;
		}

	}
	
	.wppl-address {
		margin-bottom: 1.2rem;
	}

	.gmw-location-meta-wrapper {

		h3 {
			display: none;
		}

		.gmw-location-meta {

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				
				.label {
					display: none;
				}
				
			}

		}

	}

}





/* Search Form */
.gmw-form {
	
//	@include xy-grid-container;
	
	.gmw-address-field-wrapper {
		
		@include breakpoint(medium) {
//			@include xy-cell(8);
		}
		
	}
	
	.gmw-distance-field-wrapper {
		
		@include breakpoint(medium) {
//			@include xy-cell(4);
		}
		
	}
	
}