/* Forms */


//!	GENERIC FORM STYLES
form {

	@include xy-grid-container;
	
	margin-top: 1rem;
	
	@include breakpoint(medium) {
		margin-top: 1.6rem;
	}
	
	ul {
		
		@include xy-grid;

		margin-left: 0;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;

		li {

			max-width: 100%;
			width: 100%;

			@include breakpoint(medium) {
				@include xy-cell(12);
			}

		}

	}
	
	label {
		
		color: $pj-gray-2;
		font-family: "Lulo", sans-serif;
		font-size: 0.8rem;

		.required {
			color: $pj-red;
			border: none;
		}
		
	}
	
	input[type="date"], input[type="email"], input[type="month"],
	input[type="number"], input[type="password"], input[type="range"],
	input[type="tel"], input[type="text"], input[type="time"],
	input[type="url"], textarea, select, .select2-selection {
		
		margin-bottom: 1rem;
		
		background: $pj-gray-5;
		border: rem-calc(1) solid $pj-gray-5;
		box-shadow: none;
		
		transition:	.3s linear border,
					.3s linear background;
				
		&::placeholder {

			color: $pj-gray-2;
			font-family: "Lulo", sans-serif;
			font-size: 0.8rem;

			transition: .3s linear opacity;

		}

		&:focus,
		&:active {

			background: $white;
			border: rem-calc(1) solid $pj-gold;
			box-shadow: none;

			&::placeholder {
				opacity: 0;
			}

		}
		
	}
	
	select {
		
		background-image: url('../images/svg/icon-arrow-down.svg');
		
		background-position: 98% center;
		background-repeat: no-repeat;
		
	}
	
	/*! - Error/validation styles */
	.validation_error {
		
		margin-bottom: 0.9rem;
		padding: 1.4rem 1rem 1.4rem 4.2rem;
		
		position: relative;

		color: $pj-red;
		font-weight: 600;

		&::before {
			
			content: '!';
			
			display: block;
			width: 2.4rem;
			height: 2.4rem;
			margin-top: -1.2rem;
			
			position: absolute;
			top: 50%;
			left: 1rem;

			color: $white;
			font-size: 1.8rem;
			font-family: "Lulo Bold";
			line-height: 1.5;
			text-align: center;
			text-indent: 0.4rem;
    
			background: $pj-red;
			border-radius: 100%;
			
		}
		
	}
	
	.woocommerce-invalid,
	.gfield_error {

		input[type="date"], input[type="email"], input[type="month"],
		input[type="number"], input[type="password"], input[type="range"],
		input[type="tel"], input[type="text"], input[type="time"],
		input[type="url"], textarea, select, .select2-selection {
			background: rgba($pj-red, .1);
			border: rem-calc(1) solid $pj-red;
		}
		
	}
	
	.validation_message { display: none; }
	
	
}

//!	GRAVITY FORMS
//	(ready classes, honeypot, etc.)
.hide-sublabels {
	
	label, .label
	.ginput_label {
		display: none;
	}
	
	.ginput_complex label,
	.ginput_complex .label {
		display: none;
	}
	
	input[type="date"], input[type="email"], input[type="month"],
	input[type="number"], input[type="password"], input[type="range"],
	input[type="tel"], input[type="text"], input[type="time"],
	input[type="url"], textarea, select, .select2-selection {
		@include breakpoint(medium) {
			margin-bottom: 1.9rem;
		}
	}
	
}

.gform_title,
.gform_footer {
	@include breakpoint(medium) {
		@include xy-cell(12);
	}
}

.gform_footer {
	
	input {
		@include breakpoint(small only) {
			width: 100%;
		}
	}
	
}

.ginput_complex {
	
	@include xy-grid;
		
	span {
		
		@include breakpoint(small) {
			width: 100%;
		}
		
		@include breakpoint(medium) {
	
			@include xy-cell(auto);
	
			&:first-of-type {
				margin-left: 0;
			}
	
			&:last-of-type {
				margin-right: 0;
			}
	
		}
		
	}
	
}

.gf_left_half,
.gf_right_half {
	@include breakpoint(medium) {
		@include xy-cell(6);
	}
}

.gf_left_half {
	@include breakpoint(medium) {
		@include xy-gutters(right);
//		margin-left: 0;
	}
}

.gf_right_half {
	@include breakpoint(medium) {
		@include xy-gutters(left);
//		margin-right: 0;
	}
}


.gf_left_third,
.gf_middle_third,
.gf_right_third {
	@include breakpoint(medium) {
		@include xy-cell(4);
	}
}


// Honeypot
.gform_validation_container, 
.gform_wrapper .gform_validation_container, 
body .gform_wrapper li.gform_validation_container, 
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container, 
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
	display: none !important;
	position: absolute !important;
	left: -9000px;
}


//!	WOOCOMMERCE
//	(signup, checkout, etc.)
.checkout {
	
	max-width: 100%;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	
	h3 {
		display: none;
	}
	
	.woocommerce-billing-fields__field-wrapper {
		@include breakpoint(medium) {
			@include xy-grid;
		}
	}
	
	.form-row {
		@include breakpoint(medium) {
			@include xy-cell(12);
		}
	}
	
	#billing_first_name_field,
	#billing_last_name_field {
		@include breakpoint(medium) {
			@include xy-cell(6);
		}
	}

	#billing_city_field,
	#billing_state_field,
	#billing_postcode_field {
		@include breakpoint(medium) {
			@include xy-cell(4);
		}
	}
	
	#billing_phone_field,
	#billing_email_field {
		@include breakpoint(medium) {
			@include xy-cell(6);
		}
	}
	
}