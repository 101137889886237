$black:			#000000;
$white:			#ffffff;

/* Brand Colors */
$pj-gold:		rgb(198,134,18);

$pj-gray-1:		#1b1b1b;
$pj-gray-2:		#2e2e2e;
$pj-gray-3:		#919191;
$pj-gray-4:		#b7b7b7;
$pj-gray-5:		#f3f3f3;

$pj-red:		#B0140F;

$dark-nav-color: $black;
