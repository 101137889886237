.wp-caption > figcaption {
	max-width: 100%;
	font-size: 0.8rem;
	color: #999;
	padding: 0.25rem 0;
}

p.wp-caption-text{
	font-size:90%;
	color: #666;
	padding:rem-calc(10) 0;
}

.alignleft {
	float: left;
	padding-right: 1rem;
	margin: 0;
}

.alignright {
	float: right;
	padding-left: 1rem;
	margin: 0;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
